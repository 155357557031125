import React from "react"
import { float2int } from "../helpers"
import { useQuery } from "react-query"

export default function RatesRUB() {
  const { isLoading, error, data, isFetching } = useQuery("ratesData", () =>
    fetch("https://kitco-parcer.herokuapp.com/api/v1/kitco").then(res =>
      res.json()
    )
  )

  if (isLoading) return "Загрузка..."

  if (error) return "Произошла ошибка: " + error.message

  return (
    <div style={{ fontSize: "25px" }}>
      {isFetching ? "Обновление..." : ""}
      <div className="m-5 text-center">
        В рублях
        <br />
        <br />
        Платина <b>
          {float2int((data.ptValue / 31.1) * 0.7 * data.USDRUB)}
        </b>{" "}
        руб./грамм <br />
        Палладий <b>
          {float2int((data.plValue / 31.1) * 0.7 * data.USDRUB)}
        </b>{" "}
        руб./грамм <br />
        Родий <b>
          {" "}
          {float2int((data.rhValue / 31.1) * 0.7 * data.USDRUB)}
        </b>{" "}
        руб./грамм
        <br />
      </div>
    </div>
  )
}
