export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export function getCurrentDate(separator = ".") {
  let myCurrentDate = new Date()
  let date = myCurrentDate.getDate()
  let month = myCurrentDate.getMonth() + 1
  // let year = myCurrentDate.getFullYear()

  return `${date < 10 ? `0${date}` : `${date}`}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }`
}

export function float2int(value) {
  return value | 0
}
