import React from "react"
import { useQuery } from "react-query"

export default function RatesUSD() {
  const { isLoading, error, data, isFetching } = useQuery("ratesData", () =>
    fetch("https://kitco-parcer.herokuapp.com/api/v1/kitco").then(res =>
      res.json()
    )
  )

  if (isLoading) return "Загрузка..."

  if (error) return "Произошла ошибка: " + error.message

  return (
    <div style={{ fontSize: "25px" }}>
      <div className="row m-5 text-center">
        <div className="col-12 row justify-content-between">
          <br />
          <br />
          <div className="col-12">
            {" "}
            {isFetching ? "Обновление..." : `Котировки на ${data.date}`}
          </div>
          <div className="col-12 text-center">
            $ <b>{Number(data.USDRUB).toFixed(2)}</b>
          </div>
        </div>
      </div>

      <div className="m-5 text-center">
        В долларах
        <br />
        <br />
        Platinum <b>{data.ptValue}</b> $/oz <br />
        Palladium <b>{data.plValue}</b> $/oz <br />
        RHODIUM <b>{data.rhValue}</b> $/oz
        <br />
      </div>
    </div>
  )
}
