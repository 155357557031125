import React from "react"
import HeadSection from "../HeadSection"

import Layout from "../Layout"
import RatesUSD from "./RatesUSD"
import RatesRUB from "./RatesRUB"

export default function Test() {
  return (
    <>
      <HeadSection
        title="Котировки КаталикАвто на сегодня"
        description="Котировки платины, палладия и родия в долларах и рублях"
      />
      <Layout>
        <body>
          <RatesUSD />
          <RatesRUB />
        </body>
      </Layout>
    </>
  )
}
